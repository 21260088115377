import React from 'react';
import ReactDOM from 'react-dom';
import CookieBanner from 'react-cookie-banner';

window.cookieBanner = async function cookieBanner(url, ide="cookie-banner") {
    if(document.getElementById(ide)) {
        ReactDOM.render(
            <CookieBanner
                styles={{
                    message: {fontSize: "1em", margin: "20px", lineHeight: "1em"},
                    banner: {position: "fixed", bottom: "0", backgroundColor: "rgba(89, 89, 89, 0.8)", height: "auto", padding: "10px"},
                    button: {position: "relative", margin: "10px calc( 50vw - 70px )", fontSize: "1rem", padding: "8px 25px",
                        color: "white", backgroundColor: "#2a3234", borderRadius: "0", width: "160px"}
                }}
                message="Tento web používa na poskytovanie služieb, personalizáciu reklám a analýzu návštevnosti súbory cookie. Používaním tohto webu s tým súhlasíte."
                dismissOnScroll={false}
                buttonMessage="Súhlasím"
                link={<a href={url} target={"_blank"}>Chcem vedieť viac</a>}
            />
            , document.getElementById(ide));
    }
};
